
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.ant-card-cover img {
  border-radius: 0.625rem 0.625rem 0 0;
  min-height: 300px;
  object-fit: cover;
  padding: 20px;
}

.licMeta{
  min-height: 150px;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: #72849a;
  outline: 0;
  display: none;
}
.container {
  width: 100% !important;
  max-width: 100%;
}
.mytable tfoot>tr>td,.ant-table tfoot>tr>th,.ant-table-tbody>tr>td,.ant-table-thead>tr>th {
  position: relative;
  padding: 5px;
  overflow-wrap: break-word
}
.mytablecontent .ant-card-body {
  padding: 10px;
}
.ant-card-body{
  padding: 2px;
}
.ant-card{
  font-size: 12px !important;
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #455560;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  font-size: 12px !important;
  background: #fff;
}
 
.ant-checkbox {
  top: 5px;
  border: 2px solid #d0d0d0 !important;
  border-radius: 7px !important;
}
.ant-layout{
  padding: 0px !important;
}
.rbc-event{
  border-radius: 5px !important;
}
.logo{
  text-align: center !important;
  display: block !important;
}

.chat-messages {
  flex: 3 1;
  padding: 20px;
  overflow-y: scroll;
  height: 60vh;
}
.till-end-month{
  background: #f5f5f5;
  font-weight: 800;
  border-radius: 5px;
  margin-bottom: 10px;
}
.tablereport{
  margin-top: 10px;
}
.tablereport tr{
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.tablereport td{
  border-left: 1px solid #ddd;
  padding-left: 8px;
}
.row-master-front{
  margin-top: 5px;
  margin-bottom: 4px;
  border-bottom: 1px solid #ddd;
}
.selectedrow{
  border: 2px rgb(193, 153, 153) solid;
  font-weight: 800;
}
